@import "../styles/styles.scss";

.custom-navbar {
  height: 80px;
  width: 100%;
  padding: 1.5rem 5rem 1.5rem 5rem;
  position: fixed;
  top: 0;
  background-color: $secondary-color;

  .container > .row {
    align-items: center !important;
  }
}

.vertical-wrapper {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.scrolled {
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  background-color: $secondary-color;
}

.navigation-item {
  color: white;
  text-transform: lowercase;
  font-size: 1.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin: 0 2rem;
  cursor: pointer;

  &:hover {
    color: white;
    text-decoration: none;
  }
}

.menu-toggler-icon {
  color: white;
  font-size: 20px;
}

.menu {
  position: fixed;
  height: 100%;
  width: 0;
  top: 0;
  left: 0;
  background-image: linear-gradient(
    0deg,
    $primary-color 0%,
    $secondary-color 100%
  );
  padding-top: 40px;
  transition: 0.5s;
  overflow-x: hidden;
}

.show {
  width: 40%;
}

.menu-close {
  top: 0;
  right: 0;
  position: absolute;
  padding: 5px 8px;
  opacity: 0.6;
  font-size: 30px;
  line-height: 30px;
}

@media (max-width: 767px) {
  .custom-navbar {
    padding: 1rem;
    height: 70px;
  }

  .collapsible-items {
    display: none;
  }

  .navigation-item {
    display: block;
    padding: 0;
    margin: 20px 0;
    font-size: 16px;
    text-align: center;
  }

  .menu-toggler {
    display: inline-block;
  }
}
