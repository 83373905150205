@import "~bootstrap/scss/bootstrap.scss";
@import "../notFound.scss";

@font-face {
  font-family: 'Metropolis-regular';
  src: url(../fonts/metropolis.regular.otf) format('opentype');
  font-weight: 400;
}

$primary-color: rgb(77, 178, 168);
$secondary-color: rgb(52, 134, 158);
$drop-down-bg: rgb(76, 135, 156);

body {
  background-color: $secondary-color;
  font-family: 'Metropolis-regular';
  color: white;
}

h1 {
  margin-bottom: 20px !important;
}

.subheading {
  font-size: 1.4rem;
  font-weight: 700;
  margin-bottom: 18px;
}

.content-page {
  background-image: linear-gradient(
    0deg,
    $primary-color 0%,
    $secondary-color 100%
  );
  background-size: cover;
  min-height: calc(100vh - 80px);
  margin-top: 80px;
  padding: 20px;

  a {
    color: white;

    &:hover {
      color: white;
      font-weight: 700;
    }
  }
}

.vertical-container {
  min-height: calc(100vh - 180px);
}

@media (max-width: 767px) {
  h1 {
    font-size: 1.5rem;
  }

  .subheading {
    font-size: 1rem;
  }

  body {
    font-size: 0.9rem;
  }
}
